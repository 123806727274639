import { translate } from '@fairstone/ui/core/utils/translate';
import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';

import { TLoanReason } from 'store/redux/modules/steps/types';
import { TSelectOption } from 'types/utils';

export const FRENCH_SUBDOMAIN = 'demande';

// TODO: What should be the correct type?
export const applicationType = 'iq' as const;

//Lang
export const LANGUAGE_ENGLISH = 'en-CA';
export const LANGUAGE_FRENCH = 'fr-CA';
export const INITIAL_LANGUAGE =
    window.location.hostname.startsWith(FRENCH_SUBDOMAIN) || window.location.pathname.split('/')[1] === 'fr'
        ? 'fr'
        : 'en';

export const HTML_LOCALES = {
    b: (chunks: string): JSX.Element => <b>{chunks}</b>,
    br: <br />,
    h3: (chunks: string): JSX.Element => <h3>{chunks}</h3>,
    li: (chunks: string): JSX.Element => <li>{chunks}</li>,
    span: (chunks: string): JSX.Element => <span>{chunks}</span>,
    strong: (chunks: string): JSX.Element => <strong>{chunks}</strong>,
    sub: (chunks: string): JSX.Element => <sub>{chunks}</sub>,
    sup: (chunks: string): JSX.Element => <sup>{chunks}</sup>,
    ul: (chunks: string): JSX.Element => <ul>{chunks}</ul>,
};

//Number of digit
export const AMOUNT_HUNDRED = 3;

export const EMPTY_OPTION = { label: '', value: '' };

//Alliance
export const ALLIANCE_USER_ANSWER = {
    question1_answer: 'yes',
    question2_answer: 'no',
    question3_answer: 'yes',
};

//LoanAmount
export const MIN_VALUE_BORROW = 0;
export const MAX_VALUE_BORROW = 50000;
export const STEP_VALUE_BORROW = 500;

export function formatMoneyLabel(value: number, maxValue: number, isEnglish = true): string {
    let key = value.toString();
    if (key.length > AMOUNT_HUNDRED) {
        const part2 = key.substring(key.length - 3, key.length);
        const part1 = key.substring(0, key.length - 3);
        if (isEnglish) {
            key = part1 + ',' + part2;
        } else {
            key = part1 + ' ' + part2;
        }
    }
    if (isEnglish) {
        key = '$' + key;
    } else {
        key = key + ' $';
    }
    return key;
}

export const displayYearMonthOptions = (list: number[], selectName: 'yearList' | 'monthList'): TSelectOption[] =>
    list.map((elem, index) => ({
        label: index === list.length - 1 && selectName === 'yearList' ? `${elem}+` : elem.toString(),
        value: index,
    }));

// Step 3
export const PROVINCE_MAJORITY_AGE = 18;
export const MIN_YEAR = 1900;
export const MAX_YEAR = moment().year() - 18;
export const months: string[] = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

export const getDaysOptions = (
    month: string,
    year: string
): {
    label: string;
    value: string;
}[] => {
    const days = [];

    const momentObject = month
        ? moment()
              .year(parseInt(year, 10))
              .month(parseInt(month, 10) - 1)
        : moment();

    for (let i = 1; i <= momentObject.daysInMonth(); i++) {
        const label = i.toString();
        days.push({
            label,
            value: label.padStart(2, '0'),
        });
    }

    return days;
};

export const getMonthsOptions = (
    locale: string
): {
    label: string;
    value: string;
}[] => {
    if (locale) {
        return months.map((month, index) => ({
            label: `${translate(`pages.demographic.dateOfBirth.listOfMonth.${month}`)}`,
            value: index + 1 < 10 ? `0${index + 1}` : (index + 1).toString(),
        }));
    }
    return [{ label: '', value: '' }];
};

export const getYearsOptions = (
    maxYear: number,
    minYear: number
): {
    label: string;
    value: string;
}[] =>
    Array.from({ length: maxYear - minYear + 1 }, (_, i) => ({
        label: (maxYear - i).toString(),
        value: (maxYear - i).toString(),
    }));

export const reasons: { [key: string]: TLoanReason } = {
    appliance: '10',
    debtConsolidation: '01',
    newCar: '02',
    other: '16',
    repairCar: '03',
    travel: '09',
};

export const reasonKeys = {
    [reasons.debtConsolidation]: 'pages.loanReason.reason.debtConsolidation',
    [reasons.travel]: 'pages.loanReason.reason.travel',
    [reasons.repairCar]: 'pages.loanReason.reason.repairCar',
    [reasons.appliance]: 'pages.loanReason.reason.appliance',
    [reasons.newCar]: 'pages.loanReason.reason.newCar',
    [reasons.other]: 'pages.loanReason.reason.other',
};

export const calculateAge = (year: string, month: string, day: string): number => {
    if (isEmpty(year) || isEmpty(month) || isEmpty(day)) return 0;

    const today = moment();
    const dob = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');

    return today.diff(dob, 'years');
};

export const phonePattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const toCapitalizedCase = (str: string): string =>
    str
        .split(' ')
        .map((word) => capitalize(word))
        .join(' ');

//address
export const province = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
export const postCodePattern = /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i;

export const STREETNAME_MAX_LENGTH = 36;
export const UNIT_MAX_LENGTH = 6;
export const CITY_MAX_LENGTH = 20;
export const NAME_MAX_LENGTH = 20;

export const calculateTime = (start: number): number => moment().diff(moment(start));

export const maskSin = (sin: string): string => (sin.length === 11 ? `XXX XXX ${sin.substring(8, 11)}` : sin);
