import React from 'react';

import { REGION } from 'config/constants';
import { useAnalyticsServices } from 'utils/AnalyticsService';

const AnalyticsLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { pushDataLayer } = useAnalyticsServices();

    pushDataLayer({ region: REGION });

    return <div>{children}</div>;
};

export default AnalyticsLayout;
